<template>
  <div class="details">
    <a-breadcrumb separator="">
      <a-breadcrumb-item>所在位置</a-breadcrumb-item>
      <a-breadcrumb-separator>:</a-breadcrumb-separator>
      <a-breadcrumb-item class="backHome">
        <span @click="backHome">国民健康养老网</span>
      </a-breadcrumb-item>
      <a-breadcrumb-separator />
      <a-breadcrumb-item class="backHome">
        <span @click="backList">机构展示</span>
      </a-breadcrumb-item>
      <a-breadcrumb-separator />
      <a-breadcrumb-item
        ><a>{{ data.name }}</a></a-breadcrumb-item
      >
    </a-breadcrumb>
    <div class="top">
      <div class="title">
        {{ data.name }}
      </div>
    </div>
    <a-divider />
    <!--  -->
    <div class="ql-editor" v-html="data.introduce"></div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
import { getOrganizationDetail } from '@/api/article'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      articleId: '',
      data: {},
      id: ''
    }
  },
  computed: {
    ...mapGetters({
      layoutTitle: 'layout/lyaoutTitle'
    })
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(val) {
        this.id = val.id
        this.getDetail(this.id)
      }
    }
  },
  methods: {
    async getDetail(id) {
      const { data, code } = await getOrganizationDetail(id)
      if (code == 200) {
        this.data = data
      }
    },
    backList() {
      this.$store.commit('layout/setLayoutTitle', '')
      this.$router.push('/organizationList')
    },
    backHome() {
      this.$store.commit('layout/setLayoutTitle', '')
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ {
  img {
    display: block;
    margin: auto;
    // width: 50%;
    width: 776px;
    object-fit: cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }
}
.ql-editor {
  // white-space: pre-wrap;
}
.details {
  padding: 0 160px;
  .ant-breadcrumb {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }
  .backHome {
    cursor: pointer;
  }
  .top {
    .title {
      text-align: center;
      height: 42px;
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #212223;
      line-height: 42px;
      margin: 30px 0;
    }
    .time {
      text-align: center;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: 28px;
    }
  }
  .moulde {
    margin-left: 50%;
    transform: translateX(-50%);
    width: 800px;
    height: 446px;
    margin-bottom: 52px;
    .img-content {
      position: relative;
      cursor: pointer;
      > img {
        max-width: 100%;
        // width: 800px;
        // height: 446px;
        background: transparent;
        object-fit: scale-down !important;
      }
      .img-name {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 800px;
        height: 58px;
        background: rgba(0, 0, 0, 0.1);
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 58px;
        padding-left: 15px;
      }
    }
  }
  .art-content {
    // // white-space: pre-wrap;
    // -o-tab-size: 4 !important;
    // tab-size: 4 !important;
    // -moz-tab-size: 4 !important;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .author {
    text-align: right;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #212223;
    line-height: 28px;
    span {
      display: inline-block;
      &:nth-child(2) {
        margin: 0 20px;
      }
    }
  }
}
</style>
